import { inject, Injectable, type OnDestroy } from '@angular/core';
import type { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';

import { IDLE$ } from '@cosmos/tick-scheduler';
import { injectNavigator } from '@cosmos/util-common';
import { hasGoodConnection } from '@cosmos/util-connection-state';

@Injectable({ providedIn: 'root' })
export class NetworkAwarePreloadStrategy
  implements OnDestroy, PreloadingStrategy
{
  private readonly _idle$ = inject(IDLE$);
  private readonly _navigator = injectNavigator();
  private readonly _alreadyPreloading = new Set<Route>();

  ngOnDestroy(): void {
    this._alreadyPreloading.clear();
  }

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    // Do not preload the route on the Node.js side or twice.
    if (ngServerMode || this._alreadyPreloading.has(route)) {
      return EMPTY;
    }

    // As of today, the lazy modules are not preloaded if they have been guarded with a canLoad.
    // Since the canLoad will be soon deprecated, and we have replaced the canLoad with the canMatch guard check,
    // the modules that have a canMatch guard should not be preloaded
    // https://github.com/angular/angular/blob/main/packages/router/src/router_preloader.ts#L102
    const hasCanMatchGuards = !!route.canMatch;

    const shouldPreload =
      !hasCanMatchGuards &&
      (route.data?.['preload'] === 'always' ||
        hasGoodConnection(this._navigator));

    if (shouldPreload) {
      this._preloadWhenIdling(route, load);
    }

    return EMPTY;
  }

  private _preloadWhenIdling(
    route: Route,
    load: () => Observable<unknown>
  ): void {
    this._alreadyPreloading.add(route);
    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this._idle$.subscribe(() => load());
  }
}

import {
  importProvidersFrom,
  makeEnvironmentProviders,
  type EnvironmentProviders,
} from '@angular/core';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import {
  NgxsRouterPluginModule,
  type NavigationActionTiming,
} from '@ngxs/router-plugin';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { NoopNgxsExecutionStrategy, provideStore } from '@ngxs/store';

import { environment } from './../environments/environment';

const NavigationActionTiming__PostActivation: NavigationActionTiming.PostActivation = 2;
export function provideAppNgxs(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideStore(
      [],
      {
        executionStrategy: NoopNgxsExecutionStrategy,
        developmentMode: !environment.production,
        selectorOptions: {
          /*
            For now we will still suppress errors in production, but not in dev mode.
            These should be resolved in dev mode, and then eventually we can flip this flag to be `false` in production too.
            */
          suppressErrors: environment.production,
        },
        compatibility: {
          strictContentSecurityPolicy: true,
        },
      },
      // withNgxsRouterPlugin({
      //   navigationActionTiming: NavigationActionTiming.PostActivation,
      // }),
      withNgxsStoragePlugin({
        namespace: 'supplier',
        keys: ['auth', 'appSettings'],
      }),
      withNgxsReduxDevtoolsPlugin()
    ),
    // `withNgxsRouterPlugin` seems to break the store TODO fix
    importProvidersFrom([
      NgxsRouterPluginModule.forRoot({
        navigationActionTiming: NavigationActionTiming__PostActivation,
      }),
    ]),
  ]);
}
